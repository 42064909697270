import {
  Alert,
  AttributeEditor,
  AttributeEditorProps,
  Button,
  FormField,
  Header,
  Input,
  Link,
  Select,
  Table,
} from '@amzn/awsui-components-react';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IamRoleAssociation } from '@amzn/awsdwhammerstonerepository/clients/dwhammerstonerepositoryservice';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { get } from 'lodash';

import { useLdapGroups, useGroupName, useIamRoles } from '../../data/redux';
import { DisplayMode } from 'src/components/helpers/content/contentInterfaces';
import { ReduxState } from '../../interfaces/reduxInterfaces';
import { HelpPanelInfoLink, LabeledContentSkeleton } from '../helpers';
import { useConditionalEffect } from '../../commons';
import { fetchIamRolesByGroup } from 'src/data/api/fetchFromAPI';
import { Rules } from '../helpers/content/';
import Activity from 'src/interfaces/activityInterfaces';
import { SALTIRE } from 'src/constants/ariaLabels';
import { getContentDataAttributes } from 'src/commons/dataAttributes';
import { urlSearchString, useInternalOnFollow } from 'src/nav/navHelper';
import PAGES from 'src/nav/Pages';

const label = 'IAM role(s)';
const IAM_ROLES_PATH = 'config.IAM_ROLES' as const;

interface IAMRoleObj {
  readonly type: string;
  readonly value: string;
}
const CLUSTER_ROLE_LABEL = 'Redshift cluster access role';
const CHAIN_ROLE_LABEL = 'Chain role';
const S3_ROLE_LABEL = 'S3 access role';

function iamRolesToLabeledItems(roles: { value: string }[]): IAMRoleObj[] {
  const length = roles?.length;
  if (!length) {
    return [{ type: S3_ROLE_LABEL, value: '' }];
  } else {
    return roles.map(({ value }, index) => {
      switch (index) {
        case length - 1:
          return { type: S3_ROLE_LABEL, value };
        case 0:
          return { type: CLUSTER_ROLE_LABEL, value };
        default:
          return { type: CHAIN_ROLE_LABEL, value };
      }
    });
  }
}

/**
 * Converts the IAM roles stored in the Redux store to options for the initial IAM Role `<Select>`
 *
 * Follows the filtering logic from the old Hammerstone site:  https://code.amazon.com/packages/AWSDWHammerstoneWebsite/blobs/6a73ed521ebcb835da97ffb1b4cd6a7ccea3b662/--/rails-root/app/controllers/manageactivity_controller.rb#L1833
 *
 * @param iamRolesObj An object mapping IAM arns to an IamRoleAssociation (returned from GetUserGroupIamRole RepoService API)
 * @param ldapGroups The list of LDAP groups belonging to the user (to filter out the returned roles)
 * @returns Options for the IAM Role Select component
 */
function iamRolesByGroupToOptions(iamRoles: IamRoleAssociation[], ldapGroups: string[]): OptionDefinition[] {
  const ldapGroupSet = new Set((ldapGroups ?? []).map((group) => group.toLowerCase()));

  // First filter out all invalid & disabled IAM roles
  const filteredIamRoles = (iamRoles ?? []).filter(
    (iamRole) =>
      iamRole.active && iamRole.iamRoleArn && iamRole.ldapGroup && ldapGroupSet.has(iamRole.ldapGroup.toLowerCase()),
  );

  // Then combine/aggregate them into an object mapping IAM arn to an object with a list of descriptions and ldap groups
  const combinedUniqueIamRoleArns = filteredIamRoles.reduce((accumulator, currentValue) => {
    if (!(currentValue.iamRoleArn in accumulator)) {
      accumulator[currentValue.iamRoleArn] = { ...currentValue, descriptionSet: new Set(), ldapSet: new Set() };
    }
    // Add ldap group and description to lists
    accumulator[currentValue.iamRoleArn].ldapSet.add(currentValue.ldapGroup);
    accumulator[currentValue.iamRoleArn].descriptionSet.add(currentValue.roleDescription);
    return accumulator;
  }, {} as { [arn: string]: IamRoleAssociation & { descriptionSet: Set<string>; ldapSet: Set<string> } });

  // Sort combined roles by their arn string
  const sortedCombinedIamRoles = Object.values(combinedUniqueIamRoleArns).sort((iamRoleA, iamRoleB) =>
    iamRoleA.iamRoleArn > iamRoleB.iamRoleArn ? 1 : -1,
  );

  return sortedCombinedIamRoles.map(({ iamRoleArn, descriptionSet, ldapSet }) => ({
    label: iamRoleArn,
    value: iamRoleArn,
    // Join LDAP groups and description lists into a single string
    tags: ['LDAP:', ...Array.from(ldapSet).sort()],
    description: Array.from(descriptionSet).sort().join(' / '),
  }));
}

/**
 * A custom Content component specifically for managing IAM roles and chaining.
 */
export function S3AccessIAMRoleContent(
  props: Readonly<{ mode: DisplayMode; resourceId: string; doesJobHaveOdin?: boolean }>,
) {
  switch (props.mode) {
    case DisplayMode.Edit:
      // Edit returns an attribute editor which manages role definition and chaining
      return <EditIAMRoleContent {...props} />;
    case DisplayMode.View:
      //View returns a simple table with a useful `type` column next to every role
      return <ViewActivityIAMRoleContent {...props} />;
    case DisplayMode.Loading:
      return <LabeledContentSkeleton label={label} />;
    default:
      return null;
  }
}

const maxLengthRule = {
  value: 10,
  message:
    'No more than 10 IAM roles may be defined. At most you may have 1 S3 role, 8 chain roles, and 1 Redshift cluster role)',
} as const;

function EditIAMRoleContent(props: Readonly<{ doesJobHaveOdin?: boolean }>) {
  const groupName = useGroupName();
  const ldapGroups = useLdapGroups();
  useConditionalEffect(() => {
    fetchIamRolesByGroup({ groupName });
  }, [groupName]);

  const { data: iamRolesByGroup, fetching } = useIamRoles(groupName);
  const iamRoleOptions = useMemo(() => iamRolesByGroupToOptions(iamRolesByGroup, ldapGroups), [iamRolesByGroup]);
  const { control, formState } = useFormContext<Activity>();

  const { fields, update, append, remove } = useFieldArray({
    control,
    name: IAM_ROLES_PATH,
    rules: props?.doesJobHaveOdin
      ? {
          required: false,
          maxLength: maxLengthRule,
        }
      : {
          required: Rules.required(label),
          minLength: { value: 1, message: 'At least 1 role is required, please include an S3 IAM principal below.' },
          maxLength: maxLengthRule,
        },
  });

  const definition: Readonly<AttributeEditorProps<IAMRoleObj>['definition']> = [
    { label: 'Type', control: (item) => item.type },
    {
      label: 'Role',
      errorText: (_, index) => get(formState, `errors.${IAM_ROLES_PATH}[${index}].value.message`),
      control: (item, index) => {
        const { value, type } = item || {};
        const subpath = `${IAM_ROLES_PATH}.${index}.value` as const;
        // Reconstructing the props used for content data-* attributes
        const subprops = { mode: DisplayMode.Edit, path: subpath, label: 'S3 Role', resourceType: 'activity' } as const;

        return (
          <Controller
            control={control}
            name={subpath}
            rules={{
              required: {
                value: !(props?.doesJobHaveOdin && index === 0),
                message:
                  index === 0
                    ? `An S3 IAM role is required, please select one from the dropdown. `
                    : `All included roles must be defined. To remove chain role, click the ${SALTIRE} button.`,
              },
              pattern: Rules.pattern.iamRoleArn('Role'),
            }}
            render={({ field, fieldState }) => {
              return index === 0 ? (
                <span {...getContentDataAttributes('Select', subprops)}>
                  <Select
                    selectedOption={value ? { value, label: value } : null}
                    options={iamRoleOptions}
                    filteringType="auto"
                    ref={field.ref}
                    onBlur={field.onBlur}
                    invalid={fieldState.invalid}
                    onChange={({ detail }) => {
                      update(index, { value: detail.selectedOption.value });
                      // Calling field.onChange will trigger an evaluation of whether this field violates its defined validation rules (local state)
                      field.onChange(detail.selectedOption.value);
                    }}
                    placeholder={`Select ${type}...`}
                  />
                </span>
              ) : (
                <span {...getContentDataAttributes('Text', subprops)}>
                  <Input
                    value={value}
                    ref={field.ref}
                    onBlur={field.onBlur}
                    invalid={fieldState.invalid}
                    onChange={({ detail }) => {
                      update(index, { value: detail.value });
                      // Calling field.onChange will trigger an evaluation of whether this field violates its defined validation rules (local state)
                      field.onChange(detail.value);
                    }}
                    placeholder="e.g. arn:aws:iam::123456789012:role/role_name"
                  />
                </span>
              );
            }}
          />
        );
      },
    },
  ];
  return (
    <FormField
      // TODO: Eventually implement cooldown to prevent spamming of refresh button?
      label={
        <span>
          {label}
          <Button
            iconName="refresh"
            variant="inline-icon"
            loading={fetching}
            onClick={() => fetchIamRolesByGroup({ groupName }, true)}
          />
        </span>
      }
      info={<HelpPanelInfoLink helpPanel="IamRoleChaining" />}
      errorText={get(formState, `errors.${IAM_ROLES_PATH}.root.message`)}
    >
      {props?.doesJobHaveOdin && !fields?.at(0)?.value && (
        <Alert type="warning" header="S3 Access Odin deprecation">
          This job is not configured with the appropriate permissions. Please select an IAM role below.
        </Alert>
      )}

      <span
        {...getContentDataAttributes('Text', {
          mode: DisplayMode.Edit,
          path: IAM_ROLES_PATH,
          label,
          resourceType: 'activity',
        })}
      >
        <AttributeEditor
          definition={definition}
          items={iamRolesToLabeledItems(fields)}
          onAddButtonClick={() => append({ value: '' })}
          onRemoveButtonClick={({ detail }) =>
            fields?.length > 1 ? remove(detail.itemIndex) : update(detail.itemIndex, { value: '' })
          }
          empty="No IAM roles defined"
          isItemRemovable={() => props?.doesJobHaveOdin || fields?.length > 1} // Do not remove the first role (IAM) unless it optional while migrating
          disableAddButton={fields?.length >= 10} // Do not add more than 10 roles total (1 IAM, 1 Redshift, 8 Chain)
          removeButtonText={SALTIRE}
          addButtonText={`+ Add ${fields?.length > 1 ? 'chain' : 'Redshift'} role`}
        />
      </span>
    </FormField>
  );
}

function ViewActivityIAMRoleContent(props: Readonly<{ mode: DisplayMode; resourceId: string }>) {
  const groupName = useGroupName();
  const onFollow = useInternalOnFollow();
  const roles = useSelector((state: ReduxState) => get(state.data.activity.data[props.resourceId], IAM_ROLES_PATH));

  if (!(roles?.length && roles[0]?.value?.length)) {
    return (
      <Alert type="warning" header="IAM Role(s) missing!">
        This activity is not configured with the appropriate permissions. Please{' '}
        <Link
          onFollow={onFollow}
          href={PAGES.EDIT_ACTIVITY.path.replace(':activityId', props.resourceId) + urlSearchString({ groupName })}
        >
          edit the activity
        </Link>{' '}
        and add an IAM role for Redshift-S3 access.
      </Alert>
    );
  }

  return (
    <Table
      data-testid="IAMRoleContentTable"
      sortingDisabled
      header={
        <Header variant="h3" info={<HelpPanelInfoLink helpPanel="IamRoleChaining" />}>
          {label}
        </Header>
      }
      empty={'No IAM roles defined'}
      items={iamRolesToLabeledItems(roles)}
      columnDefinitions={[
        { id: 'roleType', header: 'Type', cell: (item) => item.type },
        { id: 'roleValue', header: 'Role', cell: (item) => item.value },
      ]}
    />
  );
}
