import { Alert, Button } from '@amzn/awsui-components-react';
import React from 'react';
import { get } from 'lodash';

import { useGroupName, useOdinMaterialSets } from '../../data/redux';
import { DisplayMode } from 'src/components/helpers/content/contentInterfaces';
import { useConditionalEffect, valueListToOptions } from '../../commons';
import { fetchMaterialSets } from 'src/data/api/fetchFromAPI';
import Content from '../helpers/content/';
import { ODIN_MATERIAL_SET_PREFIX } from 'src/constants/staticUrls';

const IAM_ROLES_PATH = 'config.IAM_ROLES' as const;
const ODIN_PATH = 'config.S3_ACCESS_ODIN' as const;

export function S3AccessOdinContent(
  props: Readonly<{ mode: DisplayMode; resourceId: string; doesJobHaveIAM?: boolean }>,
) {
  const groupName = useGroupName();
  useConditionalEffect(() => {
    fetchMaterialSets({ groupName });
  }, [groupName]);
  const { data: odinMaterialSets, fetching } = useOdinMaterialSets(groupName);

  return (
    <Content.Select
      resourceType="activity"
      resourceId={props.resourceId}
      mode={props.mode}
      path={ODIN_PATH}
      infoHelpPanel="S3OdinMaterialSet"
      disableOn={{
        paths: [IAM_ROLES_PATH],
        // This logic disables the Odin dropdown if there is some IAM role defined.
        // To re-enable the Odin dropdown, a customer will have to clear their IAM roles
        condition: (value) => get(value, `${IAM_ROLES_PATH}[0].value.length`) > 0,
      }}
      // TODO: Eventually implement cooldown to prevent spamming of refresh button?
      label={
        <span>
          Odin material set for S3 source (deprecated){' '}
          {props.mode === DisplayMode.Edit && (
            <Button
              iconName="refresh"
              variant="inline-icon"
              loading={fetching}
              onClick={() => fetchMaterialSets({ groupName }, true)}
            />
          )}
        </span>
      }
      viewTransform={(materialSet) => (
        <span>
          {props.doesJobHaveIAM && (
            <Alert type="info" header="Material set overridden by IAM role">
              This activity is configured with a an IAM Role, which will take precedent over the given material set.
              <br />
              To revert to Odin (not recommended), remove all IAM roles and activate with only a material set (or
              restore a previous revision).
            </Alert>
          )}
          <Button
            iconName="key"
            iconAlt="odin material set link button"
            href={materialSet ? ODIN_MATERIAL_SET_PREFIX + materialSet : ''}
            target="_blank"
            variant="inline-icon"
          />{' '}
          {materialSet}
        </span>
      )}
      options={valueListToOptions(odinMaterialSets)}
      filteringType="auto"
    />
  );
}
