import { ColumnLayout } from '@amzn/awsui-components-react';
import React from 'react';

import { useGroupName, useS3CredentialType, useActivityInfoCache, useActivity } from '../../data/redux';
import keys from 'src/constants/hammerstoneConstantKeys';
import { S3AccessOdinContent } from './S3AccessOdinContent';
import { S3AccessIAMRoleContent } from './S3AccessIAMRoleContent';
import { DisplayMode } from '../helpers/content/contentInterfaces';

/** Renders either an IAM Role(s) Editor or an Odin selector, depending on the S3 Credential Type of the Pipeline  */
export function ActivityS3CredentialContent(props: Readonly<{ mode: DisplayMode; resourceId: string }>) {
  const activityId = props.resourceId;
  const groupName = useGroupName();
  const { pipelineName } = useActivityInfoCache(activityId)?.data ?? {};
  const s3CredentialType = useS3CredentialType({ groupName, pipelineName });
  const { data: activity } = useActivity(activityId);
  const isOdinPipeline = s3CredentialType === keys.S3CredentialType.ODIN;
  const isOdinActivity = activity?.config?.S3_ACCESS_ODIN?.length > 0;
  const isIAMActivity = activity?.config?.IAM_ROLES?.at(0)?.value?.length > 0;

  if (isOdinPipeline && isOdinActivity) {
    // NOTE: Odin is deprecated and only used for legacy pipelines
    // Legacy Odin pipelines can still be viewed & edited, but should NOT create new activities thru the UI
    return (
      <ColumnLayout columns={2} variant="text-grid">
        <S3AccessOdinContent {...props} doesJobHaveIAM={isIAMActivity} />
        <S3AccessIAMRoleContent {...props} doesJobHaveOdin={true} />
      </ColumnLayout>
    );
  } else {
    // Default is IAM for all new pipelines:
    return <S3AccessIAMRoleContent {...props} />;
  }
}
